<template>
  <CRow>
    <CCol>
      <CChartLine
          :datasets="defaultDatasets"
          labels="months"
      />
    </CCol>
  </CRow>
</template>

<script>
import http from '../http-common'
import { CChartLine } from '@coreui/vue-chartjs'

export default {

  name: 'WidgetsDropdown',
  components: {CChartLine},

  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Température extérieure',
          backgroundColor: 'rgb(50,50,250,0.2)',
          data: this.tempExt
        },
        {
          label: 'Température départ eau chaude',
          backgroundColor: 'rgb(228,50,50,0.2)',
          data: this.tempDepart
        },
        {
          label: 'Température retour de boucle ECS',
          backgroundColor: 'rgb(255,90,0,0.2)',
          data: this.tempRetour
        }

      ]
    }
  },


  data() {
    return {
      volt: [],
      tempDepart: [],
      tempRetour: [],
      tempExt: [],
      lastTempDepart: 'calcul...',
      lastTempRetour: 'calcul...',
      lastTempExt: 'calcul...',
      timer: '',
      temps: ''
    }
  },

  created () {
    this.timer = setInterval(this.maj, 3000);
  },

  methods: {
    maj() {
      http.get('/device_data/search/identifiant_device?id=ltit-device-heltec-lora32-v2-1-589a&size=40&sort=temps,desc')
          .then(response => {

            if (response.data._embedded.device_data.map(x => x.temps)[0] == this.temps) {
              return;
            } else {
              console.log('Maj')
            }
            this.temps =  response.data._embedded.device_data.map(x => x.temps)[0]

                this.tempDepart = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).temperatureDepartureValue).reverse()
                this.tempRetour = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).temperatureReturnValue ).reverse()
                this.tempExt = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).temperatureOutsideValue).reverse()

          })
          .catch(e => {
            console.log(e)
          })
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.maj()
    })
  }
}
</script>
